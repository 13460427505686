.bulk_view_card {
  height: auto !important;
  padding: 30px;
  gap: 2rem !important;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 1px var(--input-border) !important;
  margin-bottom: 20px;
  background: var(--Gray-100, #f2f4f7);
}

.payer_details_card {
  width: 85%;
}

.five_col_grid {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
}

.four_col_grid {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
}

@media screen and (max-device-width: 768px) {
  .four_col_grid {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .five_col_grid {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-device-width: 468px) {
  .five_col_grid {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }
}
