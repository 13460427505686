.form_check_input[type=checkbox]{
    background-color:#EBF0F0!important;
    border-color: #EBF0F0;
    outline: none;
    box-shadow: none;
}

.form_check_input[type=checkbox]:checked{
    background-color:var(--blue-600)!important;
    border-color: var(--blue-600)!important;;
    outline: none;
    box-shadow: none;
}

.form_check_label {
    font-size: 12px;
    font-weight: 700;
    color: #747A80;
}

.label_check {
    
    color: var(--Gray-700, #344054);
}