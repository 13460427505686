.table_badge {
  padding: 2px 8px;
  border-radius: 16px;
}

.badge_active {
  background: #ecfdf3;
  border: 1px solid #abefc6;
  color: #067647;
  font-size: small;
}
.badge_inactive {
  background: #fef3f2;
  border: 1px solid #fecdca;
  color: #b42318;
  font-size: small;
}
