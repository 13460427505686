.details_card_wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 102px;
  width: 100%;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Gray-50, #f9fafb);
  padding: 20px 24px;
}
