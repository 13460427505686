.dropdown_wrapper {
  height: 35px;
  position: relative;
  /* border-radius: 41px; */
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  padding: 0 15px;
}

.dropdown_wrapper_two {
  position: relative;
  cursor: pointer;
}

.dropdown_items {
  position: absolute;
  /* border-radius: 12px; */
  background: #fff;
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.4),
    0px 8px 24px -6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: auto;
  font-size: 14px;
  top: 40px;
  left: 0;
  z-index: 100000;
}

.dropdown_item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 40px;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  padding: 0 10px;
}

.dropdown_item:hover {
  color: #ffffff;
  background-color: var(--blue-600);
  /* border-radius: 12px; */
}

/* large screens */
@media screen and (min-device-width: 792px) {
  .dropdown_items {
    width: 100%;
  }
}
