.Button {

    .btn-primary,
    .btn-danger {
        width: 100%;
        height: 43px;
        font-size: 14px;
        line-height: 24px;
        border-radius: 8px;
    }

    // .btn-primary:hover {
    //     color: var(--blue-600);
    //     background-color: var(--white);
    // }

    .btn-primary:active {
        color: var(--white);
        background-color: var(--blue-600);
    }

    // link button
    .btn-link {
        padding: 0 !important;
        text-decoration: none !important;
        font-size: 14px;
    }

    // outline button
    .btn-outline {
        width: 100%;
        height: 43px;
        font-size: 14px;
        color: var(--dark-600);
        line-height: 24px;
        border-radius: 8px;
        border-color: var(--blue-900);
    }

    .small {
        width: auto;
        height: 35px !important;
    }

    .medium {
        width: 281px !important;
        height: 58px !important;
    }

        // .btn-outline:hover {
        //     color: #ffffff !important;
        //     background-color: var(--blue-600) !important;
        // }

    .btn-outline:active {
        color: #ffffff !important;
        background-color: var(--blue-600) !important;
    }

    .loading_item,
    .loading_item1,
    .loading_item2 {
        height: 10px;
        width: 10px;
        background-color: #ffffff;
        border-radius: 100%;
        transition: all 1s;
    }

    .loading_item {
        animation-duration: 0.7s;
        animation-iteration-count: infinite;
        animation-name: LoadingFrame;
        animation-timing-function: linear;
        animation-delay: 0.5s;
    }

    .loading_item1 {
        animation-duration: 0.7s;
        animation-iteration-count: infinite;
        animation-name: LoadingFrame;
        animation-delay: 1s;
    }

    .loading_item2 {
        animation-duration: 0.7s;
        animation-iteration-count: infinite;
        animation-name: LoadingFrame;
        animation-delay: 1.5s;
    }
}

/* medium screens */
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {}

/* large screens */
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {}

/* exrtra small screens */
@media screen and (max-width: 576px) {
    .Button {

        .btn-primary,
        .btn-outline {
            width: 100% !important;
        }
    }
}

/* Small screens */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
    .Button {

        .btn-primary,
        .btn-outline {
            width: 100% !important;
        }
    }
}

/* Wide screens */
@media screen and (min-width: 1200px) {}

@keyframes LoadingFrame {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}