.nav_item {
  list-style: none;
}

.nav_link {
  text-decoration: none;
}

.header {
  height: 80px;
  background: var(--text-00, #fff);
}

.navbar {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 1rem 3.5rem;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--blue-600);
}

.nav_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
}

.nav_item {
  margin-left: 1.5rem;
}

.nav_link {
  font-size: 1rem;
  font-weight: 400;
  color: var(--VFD-Main-Lables);
  position: relative;
}

.nav_link:hover {
  color: var(--blue-600);
  font-weight: 700;
}
.nav_link_active {
  color: var(--blue-600);
  font-weight: 700;
}
.nav_link_active::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  top: 50px;
  left: 0;
  background: var(--blue-600);
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: left;
  transform: scaleX(1);
}

.nav_logo {
  /* font-size: 2.1rem; */
  /* font-weight: 500; */
  color: var(--blue-600);
  height: 37px;
}

.left_side {
  height: 100dvh;
  background-image: url(../assets/backgroundImages/login-background.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
}

.main {
  background-color: var(--main-background);
  padding-bottom: 50px !important;
}

.profile_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.profile {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333754;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .navbar {
    padding: 1rem 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    padding: 1rem 1rem;
  }
  .nav_menu {
    position: fixed;
    left: -100%;
    top: 5rem;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }
  .nav_menu.active {
    left: 0;
    z-index: 10;
  }

  .nav_item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    margin-left: auto;
    margin-right: 0;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  .left_side {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    height: 600px;
    display: none;
  }
}

/* @media screen and (min-device-width: 792px) {
  .dropdown_items {
    width: 100%;
  }
} */
