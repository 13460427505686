.custom_dropdown {
    align-items: center;
    justify-content: center;
    min-width: 80px;
    height: 48px;
    border: 1px solid var(--dropdpown-border);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    background-color: var(--white);
  }
  .custom_dropdown_items {
    position: absolute;
    bottom: 0;
    top: 55px;
    display: flex;
    flex-direction: column;
    padding: 8px 9px 4px 5px;
    gap: 10px;
    width: 175px;
    height: fit-content;
    /* max-height: 200px; */
    overflow-y: auto;
    justify-content: center;
    z-index: 1000;
    background: #ffffff;
  
    box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16),
      0px 0px 1px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    transition:0.2s all;
  }
  .custom_dropdown_items span {
    font-size: 12px;
  }
  .custom_dropdown_item {
    gap: 4px;
    align-items: center;
    padding: 2px 0;
    padding-left: 5px;
    
  
  
  }
  .custom_dropdown_item:hover {
    color: var(--blue-900);
    background: #eff5fa;
    border-radius: 4px;
  }



  /* medium screens */
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  
}
/* large screens */
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}
/* exrtra small screens */
@media screen and (max-width: 576px) {
  .custom_dropdown_items{
    left: 5px;
  }
  
  
}
/* Small screens */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
 
}
/* Wide screens */
@media screen and (min-width: 1200px) {
}