.wrapper {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top:-20px;
}

.card_container {
    padding: 28px;
    margin-left: 40px;
    display: 'flex';
    flex-direction: column;
    gap: 10px;
}

/* medium screens */
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {}

/* large screens */
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {}

/* exrtra small screens */
@media screen and (max-width: 576px) {
    .wrapper {
    margin-top:-100px;
    }

    .card_container {
        margin: 0px 20px 0px 30px;

    }
    
}

/* Small screens */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
    .wrapper {
        margin-top:-100px;
        }
    
        .card_container {
            margin: 0px 20px;
        }
        
}

/* Wide screens */
@media screen and (min-width: 1200px) {}

@keyframes LoadingFrame {

}