.form_wrapper {
  padding: 5px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 1px var(--input-border) !important;
  margin-bottom: 20px;
  margin-top: 25px;
}
.card_wrapper {
  padding: 5px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 1px var(--input-border) !important;
  margin-bottom: 20px;
}

.form_section_one {
  border-bottom: solid 2px #eaecf0;
  padding-bottom: 20px;
}
.form_section_two {
  border-bottom: solid 2px #eaecf0;
  padding-top: 20px;
  padding-bottom: 20px;
  /* margin-bottom: 20px; */
}
.success_inner_card {
  display: flex;
  flex-direction: column;
  gap: 15px !important;
  width: 65%;
  padding: 15px;
  gap: 15px;
  box-shadow: none !important;
  border-radius: 12px;
}
.info_container {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);

  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 102px;
  width: 100%;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Gray-100, #f2f4f7);
  padding: 20px 22px;
  gap: 15px;
}
.circle {
  display: flex;

  width: 40px;
  height: 40px;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 48px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);

  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.info_dark {
  border-radius: var(--interactive-border-radius---radius-i-sm, 8px) !important;
  border: 1px solid var(--Gray-300, #d0d5dd) !important;
  background: var(--Gray-100, #f2f4f7) !important;
  min-height: 162px !important;
  justify-content: flex-start !important;
}
.upload_area {
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  height: 188px;
  display: flex;
  height: 188px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.upload_icon_container {
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
/* medium screens */
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
}

/* large screens */
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
  .container {
    max-width: 830px;
  }
}

/* exrtra small screens */
@media screen and (max-width: 576px) {
  .success_inner_card {
    width: 100%;
  }
}

/* Small screens */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .success_inner_card {
    width: 100%;
  }
}

/* Wide screens */
@media screen and (min-width: 1200px) {
  .container {
    max-width: 830px !important;
  }
}
