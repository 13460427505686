.form_wrapper {
  padding: 5px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 1px var(--input-border) !important;
  margin-bottom: 20px;
  margin-top: 25px;
}
.card_wrapper {
  padding: 5px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 1px var(--input-border) !important;
  margin-bottom: 20px;
}

.form_section_one {
  border-bottom: solid 2px #eaecf0;
  padding-bottom: 20px;
}
.form_section_two {
  border-bottom: solid 2px #eaecf0;
  padding-top: 20px;
  padding-bottom: 20px;
  /* margin-bottom: 20px; */
}
.success_inner_card {
  display: flex;
  flex-direction: column;
  gap: 15px !important;
  width: 65%;
  padding: 15px;
  gap: 15px;
  box-shadow: none !important;
  border-radius: 12px;
}
.info_container {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);

  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 102px;
  width: 100%;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Gray-100, #f2f4f7);
  padding: 20px 22px;
  gap: 15px;
}

/* large screens */
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
  .container {
    max-width: 780px;
  }
}

/* exrtra small screens */
@media screen and (max-width: 576px) {
  .success_inner_card {
    width: 100%;
  }
}

/* Small screens */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .success_inner_card {
    width: 100%;
  }
}

/* Wide screens */
@media screen and (min-width: 1200px) {
  .container {
    max-width: 780px;
  }
}

.mandate_card {
  padding: 30px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 1px var(--input-border) !important;
  margin-bottom: 20px;
  gap: 10px;
  flex-wrap: wrap;
  text-align: center;
}

.mandate_card > :nth-child(1) {
  border-right: none;
}
.mandate_card > :nth-child(2) {
  border-right: none;
}

.batch_mandate_card {
  height: 100% !important;
  padding: 20px 30px !important;
  flex-wrap: wrap !important;
}

.mandate_card_numbers {
  font-size: xx-large !important;
  font-weight: 900 !important;
}

.mandate_table {
  display: flex !important;
  justify-content: space-between !important;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 0px var(--input-border) !important;
  margin-bottom: 20px;
}

.single_mandate {
  gap: 10px;
}

.bulk_mandate {
  gap: 0px;
}

.mandate_btn {
  background: white !important;
  border: 1px solid var(--input-border) !important;
  height: 48px !important;
  border-radius: 8px;
  color: var(--text-dark) !important;
}

.generic_inputfield_style {
  height: 48px !important;
  border-radius: 8px;
  border: 1px solid var(--input-border) !important;
  /* max-width: 100% !important; */
  overflow: hidden;
  width: fit-content;
  min-width: auto;
}

.generic_inputfield_style > button {
  border: none;
  background: white;
  font-weight: 600;
  min-width: 90px;
  padding-inline: 1rem;
}

.table_header {
  --bs-table-bg: #f9fafb;
  background-color: var(var(--bs-table-bg));
}
.table_header > td {
  font-weight: 400 !important;
}

.table_badge {
  padding: 2px 8px;
  border-radius: 16px;
}

.badge_active {
  background: #ecfdf3;
  border: 1px solid #abefc6;
  color: #067647;
  font-size: small;
}
.badge_inactive {
  background: #fef3f2;
  border: 1px solid #fecdca;
  color: #b42318;
  font-size: small;
}
.badge_pending {
  background: #fffaeb;
  border: 1px solid #fedf89;
  color: #b54708;
  font-size: small;
}

/* medium screens  and above */
@media screen and (min-device-width: 768px) {
  .mandate_card {
    /* min-height: 100% !important; */
    padding: 30px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    border-radius: 8px !important;
    box-shadow: none;
    border: solid 1px var(--input-border) !important;
    margin-bottom: 20px;
    gap: 10px;
    flex-wrap: wrap;

    text-align: left;
  }
  .mandate_card.withBorder > :nth-child(1) {
    border-right: solid 1px var(--input-border) !important;
  }
  .mandate_card.withBorder > :nth-child(2) {
    border-right: solid 1px var(--input-border) !important;
  }
}
