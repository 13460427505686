.dashboard_card {
  height: 183px !important;
  padding: 15px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  gap: 10px;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 1px var(--input-border) !important;
  margin-bottom: 20px;
}
.graph_card {
  height: 431px !important;
  padding: 15px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  gap: 10px;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 1px var(--input-border) !important;
  margin-bottom: 20px;
}

.graph_card_two {
  height: 540px !important;
  padding: 15px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  gap: 10px;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 1px var(--input-border) !important;
  margin-bottom: 20px;
}

.graph_part {
  width: 70%;
}

.mandate_button_wrapper {
  width: 45%;
}

.collection_card_wrapper {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Light-Goku, #f5f5f5);
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 100%;
}

.recentCollectionWrapper {
  display: flex;
  padding: 15px 19px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: var(--Gray-100, #f2f4f7);
  margin-bottom: 20px;
}

.recentWrapper {
  padding: 15px;
  height: 431px !important;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: none;
  margin-bottom: 20px;
}

.wrapperTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
}

.dropdown_wrapper {
  border: none !important;
  box-shadow: none !important;
}

.analysis {
  padding: 15px;
  min-height: 140px !important;
  border-radius: 8px !important;
  box-shadow: none;
  border: solid 1px var(--input-border) !important;
  margin-bottom: 20px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  gap: 10px;
}

.analysis > div > :nth-child(1) {
  border-right: solid 1px var(--input-border) !important;
}
.analysis > div > :nth-child(2) {
  border-right: solid 1px var(--input-border) !important;
}

/* medium screens */
@media screen and (max-device-width: 991px) {
  .analysis > div > :nth-child(1) {
    border-right: none !important;
  }
  .analysis > div > :nth-child(2) {
    border-right: none !important;
  }
  .analysis {
    text-align: center;
  }
  .graph_part {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .graph_card_two {
    height: 100%;
    padding: 15px;
  }
  .graph_part {
    width: 100%;
  }
}

/* large screens */
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}

/* exrtra small screens */
@media screen and (max-width: 576px) {
  .mandate_button_wrapper {
    width: 100%;
    z-index: 2;
  }
  .analysis {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 10px;
    flex-wrap: wrap;
    height: 100% !important;
    text-align: center;
  }
}

/* Small screens */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .mandate_button_wrapper {
    width: 100%;
    z-index: 2;
  }
  .analysis {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 10px;
    flex-wrap: wrap;
    text-align: center;
  }
  /* .graph_part {
    width: 100%;
  } */
}

/* Wide screens */
@media screen and (min-width: 1200px) {
}

@media screen and (max-width: 990px) {
  .graph_card_two {
    height: 100% !important;
    padding: 15px;
  }
}
