.profileCard {
  background: #f9fafb !important;
  box-shadow: none;
}
.profile_image {
  border-bottom: 1px solid var(--bs-border-color);
}

.userDetailsWidth {
  width: 100%;
  border-bottom: none;
}

@media screen and (min-width: 777px) {
  .profile_image {
    border-right: 1px solid var(--bs-border-color);
    border-bottom: none;
  }

  .userDetailsWidth {
    width: 75%;
    border-bottom: 1px solid var(--bs-border-color);
  }
}
