.tabs {
    font-size: 14px;
    display: flex;
    justify-content: center;
    /* width: 50%; */
  }
  .tabs .btn_link {
    outline: none;
    box-shadow: none;
    color: #999ca0;
    font-weight: 700;
    border-radius: 0!important;
    border-bottom: none;
  }
  .tabs.active > .btn_link {
    color: var(--blue-600);
  }
  .tabs.active {
    border-bottom: solid 2px var(--blue-600);
    color: var(--blue-600);
    transition: 0.3s all;
  }