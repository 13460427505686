.Input-with-label {
  margin-bottom: 10px;

  textarea {
    resize: none;
  }

  .form-control,
  .form-select {
    max-width: 100%;
    height: 48px !important;
    color: var(--text-dark) !important;
    border: 1px solid var(--input-border);
    border-radius: 8px;
    background-color: var(--white);
    font-size: 14px;
  }

  .form-control::placeholder {
    color: var(--placeholder) !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }

  .form-control:focus,
  .form-select:focus {
    border-color: var(--blue-600) !important;
    box-shadow: none !important;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    color: var(--black);
    margin-bottom: 0.5rem;

    .Required {
      color: var(--danger);
    }
  }
}

.Scheme {
  border: solid 1px rgba(46, 113, 255, 0.21);
  background: rgba(46, 113, 255, 0.21);
  border-radius: 24.5px;
  padding: 0 10px;

  p {
    margin-bottom: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.35);
  }
}

.search_text_container {
  position: relative;

}

.search_icon {
  position: absolute;
  top: 12px;
  bottom: 0;
  left: 15px;
}

.form_control {
  padding: 0 45px !important;

  max-width: 100%;
  height: 48px !important;
  color: var(--text-dark) !important;
  border: 1px solid var(--input-border);
  border-radius: 8px;
  background-color: var(--white);
  font-size: 14px !important;
}

.form_control::placeholder {
  color: var(--placeholder) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.form_control:focus {
  border-color: var(--blue-600) !important;
  box-shadow: none !important;
}
/* medium screens */
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {}

/* large screens */
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {}

/* exrtra small screens */
@media screen and (max-width: 576px) {
  .Input-with-label {

    .form-control,
    .form-select {
      width: 100% !important;
      height: 51px !important;
    }
  }
}

/* Small screens */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .Input-with-label {

    .form-control,
    .form-select {
      width: 100% !important;
      height: 51px !important;
    }
  }
}

/* Wide screens */
@media screen and (min-width: 1200px) {}