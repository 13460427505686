@font-face {
  font-family: Inter-Regular;
  src: url(./assets/fonts/Inter-Regular.ttf);
}
@font-face {
  font-family: Inter-Bold;
  src: url(./assets/fonts/Inter-Bold.ttf);
}
@font-face {
  font-family: Inter-Black;
  src: url(./assets/fonts/Inter-Black.ttf);
}
@font-face {
  font-family: Inter-Extra-Bold;
  src: url(./assets/fonts/Inter-ExtraBold.ttf);
}


body {
  margin: 0;
  font-family: Inter-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --blue-600: #1570EF;
  --grayscale-body: #394455;
  --vfd-main-body-text: #2C2A3B;
  --text-dark:#4B5563;
  --placeholder:#AAA;
  --black:#000;
  --danger:#D92D20;
  --input-border:#D6DDE0;
  --dropdpown-border: #E5E5E5;
  --white:#FFFFFF;
  --VFD-Main-Lables: #59595E;
  --main-background:#F6F8FA;
  --Light-Goku: #F5F5F5;
  --green: #40A69F;
  --error: #F04438;
  --Light-Trunks: #999CA0;
  --Supportive-Roshi-100: #4AD15F;
  --Gray-100: #F2F4F7;
  --vfd-green-vfd-green-500:#188E47;
  --VFD-Main-Placeholder: #AAA;
  --vfd-yellow-vfd-yellow-500: #FFC31A;
  --SPN-900: #0D0F11;
  --Gray-700: #344054;
  --Gray-50: #F9FAFB;
  --Gray-200: #EAECF0;
  --Gray-600: #475467;
  --Gray-300: #D0D5DD;
}

/* Date picker */

.react-datepicker__view-calendar-icon input {
width: 100%!important;
    height: 48px !important;
    color: var(--text-dark) !important;
    border: 1px solid var(--input-border) !important;
    border-radius: 8px;
    background-color: var(--white);
    font-size: 14px;
}
.react-datepicker__view-calendar-icon input::placeholder {
  color: var(--placeholder) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.react-datepicker__view-calendar-icon input:focus,
.react-datepicker-ignore-onclickoutside input:focus {
  border-color: var(--blue-600) !important;
  box-shadow: none !important;
  outline: none!important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__view-calendar-icon >svg {
  position: absolute;
  display: flex;
 top:12px;
 right:10px
}

.react-datepicker__header {
  background-color: #ffffff!important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}